.Footer {
  padding: 48px 0px;
  background: #EBF2FB;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  a {
    color: var(--accent); }
  .Main {
    display: flex;
    justify-content: space-between; }
  .ContactBlock {
    display: flex;
    align-items: center;
    // display: grid
    // grid-template-columns: repeat(2, max-content)
    // grid-gap: 32px
    // align-content: center
 }    // align-items: center
  .Line {
    width: 100%;
    height: 1px;
    background: #CAD0D5;
    margin: 24px 0px; }
  .Footer-InfoContainer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    .Title {
      grid-column: 1/-1;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      color: var(--black); }
    .Clinic {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-gap: 12px; }

    .LinkBlock {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      align-content: start;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      a {
        color: var(--darkGray); }
      &:not(:last-child) {
        margin-right: 64px; } }
    .Contacts {
      text-align: right;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      justify-items: end; }
    .Address {
      display: flex;
      align-items: center;
      color: var(--darkGray) !important;
      .Icon {
        color: var(--accent);
        font-size: 16px;
        margin-right: 12px; } }
    .BusyHours {
      font-size: 14px;
      line-height: 20px;
      color: var(--darkGray);
      font-weight: 500; } }
  .Copyrights {
    font-size: 14px; } }

@media screen and ( max-width: 1024px ) {
  .Footer {
    .Main {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; }
    .ContactBlock {
      justify-content: space-between; } } }

@media screen and ( max-width: 1023px ) {
  .Footer {
    .Main {
      justify-items: center; }
    .ContactBlock {
      grid-template-columns: 1fr;
      text-align: center;
      justify-items: center; }
    .Footer-InfoContainer {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      grid-gap: 48px;
      .Clinic {
        grid-template-columns: repeat(3, max-content); }
      .Contacts {
        text-align: center;
        .Address {
          justify-content: center; } } }
    .Copyrights {
      text-align: center; } } }
@media screen and ( max-width: 768px ) {
  .Footer {
    .ContactBlock {
      .PhoneLinks {
        display: grid;
        grid-template-columns: 1fr; } }
    .Footer-InfoContainer {
      .LinkBlock {
        margin-right: 0 !important; }
      .Clinic {
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center; }
      .Contacts {
        text-align: center;
        justify-items: center;
        .SocialLinks {
          .Title {
            display: none; } } } } } }

// @media screen and ( max-width: 992px )
//   .Footer
//     .Main
//       flex-direction: column
//     .ContactBlock
//       margin-top: 24px
//       display: flex
//       justify-content: space-between
//     .Footer-InfoContainer
//       .Clinic
//         grid-template-columns: 1fr
// @media screen and ( max-width: 768px)
//   .Footer
//     .Main
//       flex-direction: row
//     .ContactBlock
//       display: grid
//       grid-template-columns: 1fr
//       grid-gap: 12px
//       margin-top: 0
//       justify-items: end
// @media screen and ( max-width: 576px)
//   .Footer
//     .Logo
//       width: 236px
//       margin-left: 50%
//       transform: translateX(-50%)
//     .Main
//       flex-direction: column
//       align-items: left
//     .ContactBlock
//       margin-top: 24px
//       text-align: left
//       justify-items: left

//     .Footer-InfoContainer
//       display: grid
//       grid-template-columns: 1fr
//       grid-gap: 24px
//       .Clinic
//         grid-template-columns: 1fr
//       .Contacts
//         text-align: left
//     .PhoneLinks
//       .PhoneIcon
//         margin-left: 0
