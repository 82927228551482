.Top {
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  justify-content: center; }

.Top-Container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  .SocialLinks {
    margin-left: 32px; } }

.Top-Contact-Buttons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 32px; }

.Top-Service-Buttons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 32px; }

// @media screen and ( max-width: 1023px )
//   .Top
//     height: auto
//     .Top-Container
//       display: grid
//       grid-template-columns: 1fr
//       grid-gap: 16px
//       justify-items: center

@media screen and ( max-width: 768px ) {
  .Top {
    display: none; } }
