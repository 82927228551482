.SitePage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 0px; }
.SitePage-Container {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 32px;
  align-items: start; }

@media screen and ( max-width:  1023px) {
  .SitePage-Container {
    grid-template-columns: 1fr; } }
