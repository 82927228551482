.ServicesPage {
  .Title {
    font-size: 24px;
    color: var(--accent2);
    font-weight: 700;
    margin-bottom: 24px;
    text-transform: uppercase; }
  .Body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    h5 {
      font-size: 18px;
      margin-bottom: 0;
      color: var(--accent2); }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li::marker {
        font-size: 18px;
        color: var(--accent2); } } }
  .Photos {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 16px;
    align-content: start;
    align-items: start;
    align-self: start;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover; } } }

@media screen and ( max-width: 576px ) {
  .ServicesPage {
    .Photos {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } } }
