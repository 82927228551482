.About-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8);
  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: var(--accent2);
    margin-top: 24px; } }
.Hero-Categories {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  .CategoryTile {
    background-color: var(--lightGray);
    border-radius: 8px;
    img {
      width: 100%;
      height: 250px;
      border-radius: 8px;
      object-fit: cover;
      object-position: top center; }
    .Title {
      font-size: 21px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--accent2); }
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px;
      grid-gap: 16px; } } }
.PurposeText {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  ul {
    list-style-type: disc;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    li::marker {
      font-size: 18px;
      color: var(--accent2); } } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; }
  .AboutVideo {
    height: 250px; } }
@media screen and ( max-width: 992px ) {
  .About-Container {
    grid-template-columns: 1fr; }
  .AboutVideo {
    height: 300px; } }

@media screen and ( max-width: 768px ) {
  .Hero-Categories {
    grid-template-columns: 1fr; } }
