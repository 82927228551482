.Edu {
  .Hero-Categories {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 16px; } }
.BreadCrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .BreadCrumbs_Link_active {
    color: var(--accent); }

  a {
    color: var(--darkGray);
    &:hover {
      color: var(--accent);
      text-decoration: underline !important; } }
  span {
    &:not(last-child) {
      margin-right: 8px; } } }

@media screen and ( max-width: 1024px ) {
  .Edu {
    .Hero-Categories {
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }
