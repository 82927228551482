.PhoneLinks {
  display: flex;
  align-items: center;
  .Icon {
    color: var(--accent); }

  a {
    color: var(--accent) !important;
    margin-left: 12px;
    &:hover {
      text-decoration: underline !important; } }

  .PhoneNumber {
    color: var(--black);
    font-size: 16px;
    font-weight: 700; } }
