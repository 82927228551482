.Doctors {
  width: 100%; }
.Doctors-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 48px; }
.DoctorTile {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 32px;
  .Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start; }
  .Langs {
    color: var(--accent2);
    font-size: 18px; }
  .Details {
    .Header {
      padding: 8px 16px;
      background-color: var(--accent2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: #fff;
      text-transform: uppercase;
      border-radius: 8px;
      .Icon {
        font-size: 21px; } }
    .SubCategory {
      padding: 8px 16px;
      border-bottom: 1px var(--lightGray) solid;
      cursor: pointer;
      display: block;
      color: var(--black);
      &:hover {
        color: var(--accent2); } }
    .Body {
      display: none; } }
  .Spoiler_status_expanded {
    .Header {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .Icon {
        transform: rotate(180deg); } }
    .Body {
      display: block;
      background-color: var(--lightGray);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 16px;
      ul {
        list-style-type: disc;
        padding-left: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
        li::marker {
          font-size: 18px;
          color: var(--accent2); } } } }

  .Avatar {
    width: 100%;
    height: 300px;
    position: relative;
    background-color: #F9FCFE;
    border-radius: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 56px;
    color: var(--accent2);

    img {
      border-radius: 16px;
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: top center; } }
  .Title {
    color: var(--accent2);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px; }
  .ShortDesc {
    font-weight: 400;
    line-height: 22px;
    color: var(--darkGray); } }

@media screen and ( max-width: 1024px ) {
  .DoctorTile {
    grid-template-columns: 200px 1fr;
    .Avatar {
      height: 200px;
      img {
        height: 200px; } } } }

@media screen and ( max-width: 576px ) {
  .DoctorTile {
    grid-template-columns: 1fr; } }
