.Site-Hero {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  background-color: var(--background);
  align-items: center;
  margin-bottom: 64px;
  .SiteHero-Container {
    width: 868px; }
  .Hero-Carousel {
    width: 100%;
    // height: 350px
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: top center; } } }
@media screen and ( max-width: 1200px ) {
  .Site-Hero {
    .SiteHero-Container {
      width: 660px; } } }

@media screen and ( max-width: 1024px ) {
  .Site-Hero {
    .SiteHero-Container {
      width: 736px; } } }

@media screen and ( max-width: 768px ) {
  .Site-Hero {
    height: 250px;
    .Hero-Carousel {
      height: 250px;
      img {
        height: 250px; } }
    .SiteHero-Container {
      width: 544px; } } }

@media screen and ( max-width: 576px ) {
  .Site-Hero {
    .SiteHero-Container {
      width: 375px; } } }
