.Equipment-Container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;
  margin-top: 48px;
  .ModuleSpinner-Container {
    grid-column: 1/-1; }
  .EquipmentTile {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start;
    text-align: center;
    img {
      width: 100%;
      height: 400px;
      object-fit: contain;
      object-position: center center;
      border-radius: 16px; }
    .Title {
      font-size: 18px;
      color: var(--accent2);
      text-transform: uppercase; }

    .DescriptionShort {
      color: var(--darkGray); } } }
@media screen and ( max-width: 768px ) {
  .Equipment-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }

@media screen and ( max-width: 450px ) {
  .Equipment-Container {
    grid-template-columns: 1fr; } }
