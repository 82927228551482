.SectionTitle {
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  color: var(--accent2);
  display: inline-block;
  font-family: var(--titleFont);
  display: flex;
  align-items: center;
  margin-bottom: 32px; }

@media screen and ( max-width: 576px ) {
  .SectionTitle {
    font-size: 32px;
    line-height: 32px; } }
