.Partners {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  .Partners-Container {
    width: 868px; }
  .PartnerTile {
    width: 100%;
    // height: 100px
    // padding: 8px
    background-color: #fff;
    // border: 2px var(--lightGray) solid
    display: block;
    border-radius: 12px;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; } } }
@media screen and ( max-width: 1200px ) {
  .Partners {
    .Partners-Container {
      width: 660px; } } }

@media screen and ( max-width: 768px ) {
  .Partners {
    .Partners-Container {
      width: 544px; } } }

@media screen and ( max-width: 576px) {
  .Partners {
    .Partners-Container {
      width: 375px; } } }
