.Actions {}
.Actions-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .ActionTile {
    padding-bottom: 16px;
    border-bottom: 1px var(--lightGray) solid;
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: center center; }
    .Text {
      padding: 16px; } } }

@media screen and ( max-width: 768px ) {
  .Actions-Container {
    .ActionTile {
      img {
        height: 250px; } } } }
