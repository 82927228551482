.ServicesNav {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  .Title {
    text-transform: uppercase;
    font-size: 18px;
    background-color: var(--accent);
    color: #fff;
    padding: 8px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Icon {
      font-size: 24px; } }
  .Spoiler_status_expanded {
    .Header {
      color: var(--accent2); }
    .Icon {
      transform: rotate(180deg); }
    .Body {
      display: block; } }
  .ServicesNav-Body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px; } }
.ServiceSpoiler {
  .active {
    color: var(--accent2) !important; }
  .Header {
    padding: 8px 16px;
    background-color: var(--lightGray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--black);
    .Icon {
      font-size: 21px; } }
  .SubCategory {
    padding: 8px 16px;
    border-bottom: 1px var(--lightGray) solid;
    cursor: pointer;
    display: block;
    color: var(--black);
    &:hover {
      color: var(--accent2); } }
  .Body {
    display: none; } }

@media screen and ( max-width: 1023px ) {
  .ServicesNav {
    .Title {
      border-radius: 8px; }
    .ServicesNav-Body {
      display: none; } }

  .Spoiler_status_expanded {
    .Title {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      .Icon {
        transform: rotate(180deg) !important; } }
    .ServicesNav-Body {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px; } } }
