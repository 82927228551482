.EduTiles {}
.EduTiles-Container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;
  .ModuleSpinner-Container, .EmptyContent {
    text-align: center;
    color: var(--darkGray);
    grid-column: 1/-1; }
  .EduTile {
    background-color: var(--lightGray);
    border-radius: 8px;
    img {
      width: 100%;
      height: 250px;
      border-radius: 8px;
      object-fit: cover;
      object-position: center center; }
    .Title {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--accent2); }
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px;
      grid-gap: 16px; } } }

@media screen and ( max-width: 1200px ) {
  .EduTiles-Container {
    grid-template-columns: 1fr; } }
